import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    class: _normalizeClass($setup.props.inputclass),
    ref: "inputRef",
    modelValue: $setup.formattedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formattedValue) = $event))
  }, {
    suffix: _withCtx(() => [
      _createTextVNode(_toDisplayString($props.symbol), 1)
    ]),
    _: 1
  }, 8, ["class", "modelValue"]))
}