
import { useCurrencyInput, CurrencyInputOptions } from "vue-currency-input"
import { watch } from "vue"

export default {
  name: "ElInputCurrency",
  props: {
    modelValue: Number,
    options: Object,
    inputclass: String,
    symbol: String,
  },
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput(
      (props.options as CurrencyInputOptions) ?? {
        currencyDisplay: "hidden",
        currency: "TRY",
        locale: "tr-TR",
        valueRange: {
          min: 0,
        },
        ...props.options,
      }
    )

    watch(
      () => props.modelValue,
      value => {
        setValue(value as number | null)
      }
    )

    return { inputRef, formattedValue, props }
  },
}
